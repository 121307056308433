<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
.matched {
  background-color: rgb(173, 227, 173);
}
.remove-button,
.add-button {
  font-size: 1.5em;
  background-color: #ff5c5c; /* Red color for remove button */
  color: #fff; /* White text color for buttons */
  border: none;
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border-radius: 5px;
}
.add-button {
  background-color: #5cb85c;
  margin-right: 10px;
}
</style>
<template>
  <div class="vx-col w-full mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice OCR</h4>
    </span>
    <vs-divider style="width: 100%; margin-left: 0%">Supplier Data</vs-divider>
    <div class="vx-row mb-2">
      <div v-if="this.invoice_number == ''" class="vx-col w-1/2">
        <label class="vs-input--label">Supplier Code</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Code"
          :custom-label="codeWithName"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span v-if="!dt.option.Code" class="option__title">
                Type to search
              </span>
              <span v-else class="option__title">
                {{ dt.option.Code }} - {{ dt.option.Name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.Code }} - {{ dt.option.Name }}
              </span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="!supplier.ID && status_submit"
          >This field is required</span
        >
      </div>
      <div v-else class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Code"
          name="Supplier Code"
          v-model="supplier_code"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Supplier Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="table.bank"
          v-validate="'required'"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="track_by"
          :disabled="false"
          :custom-label="codeWithSupplier"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} {{ dt.option.account_name }} ({{
                  dt.option.account_number
                }}) - {{ dt.option.territory_code }}
                {{ dt.option.territory_name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} {{ dt.option.account_name }} ({{
                  dt.option.account_number
                }}) - {{ dt.option.territory_code }}
                {{ dt.option.territory_name }}
              </span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.bank.bank_name == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          v-if="supplier.ID == 0"
          class="w-full"
          label="Supplier Name"
          name="Supplier Name"
          v-model="supplier_name"
          placeholder="Supplier Name"
        />
        <vs-input
          v-else
          class="w-full input-readonly"
          label="Supplier Name"
          name="Supplier Name"
          v-model="supplier_name"
          placeholder="Supplier Name"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Supplier Tax Number"
          name="Supplier Tax Number"
          v-model="tr_number"
          placeholder="Supplier Tax Number"
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Supplier PIC"
          name="Supplier PIC"
          v-model="supplier_contact_name"
          placeholder="Supplier PIC"
        />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Supplier Tax Date</label>
        <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Date"
          format="yyyy-MM-dd"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="PIC Phone Number"
          name="PIC Phone Number"
          v-model="supplier_phone"
          placeholder="PIC Phone Number"
          @keypress="isNumber($event)"
        />
      </div>
      <div class="vx-col w-1/2">
        <!-- <vs-input
          class="w-full"
          label="Tax Form Value"
          name="Tax Form Value"
          v-model="tax_from_value"
          @keypress="isNumber($event)"
          @input="formatNumber(0, 'tax_from_value')"
          @focus="$event.target.select()"
          placeholder="Tax Form Value"
        /> -->
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 0%"
      >PO / GR Data & Invoice Data</vs-divider
    >
    <div class="vx-row mb-2">
      <div v-if="this.invoice_number == ''" class="vx-col w-1/2">
        <label class="vs-input--label">Warehouse</label>
        <multiselect
          class="selectExample"
          v-model="warehouse"
          :options="optionWarehouse"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="warehouse_id"
          :custom-label="customLabel"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.warehouse_code }} -
                {{ dt.option.warehouse_name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title"
                >{{ dt.option.warehouse_code }} -
                {{ dt.option.warehouse_name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="warehouse == null && status_submit"
          >This field is required</span
        >
      </div>
      <div v-else class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouse_name"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <label for="trade_invoice_checkbox">Trade Invoice</label>
        <div class="vs-checkbox-con">
          <input
            type="checkbox"
            id="trade_invoice_checkbox"
            v-validate="'required'"
            v-model="trade_invoice"
            disabled
            :checked="trade_invoice"
            style="transform: scale(2); margin-top: 12px"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div v-if="this.invoice_number == ''" class="vx-col w-1/2">
        <label class="vs-input--label">PO Code</label>
        <multiselect
          class="selectExample"
          v-model="po"
          :options="optionPO"
          :multiple="true"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="code"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="po == null && status_submit"
          >This field is required</span
        >
      </div>
      <div v-else class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="po_code"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Invoice Type"
          name="Invoice Type"
          v-model="invoice_type"
          readonly
          placeholder="Invoice Type"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Date"
          name="PO Date"
          v-model="po_date"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Invoice Number"
          name="Invoice Number"
          v-model="invoice_number"
          readonly
          placeholder="Invoice Number"
        />
      </div>
    </div>

    <div v-if="this.po_type == '0'" class="vx-row mb-2">
      <div v-if="this.invoice_number == ''" class="vx-col w-1/2">
        <label class="vs-input--label">Proof of GR Code</label>
        <multiselect
          class="selectExample"
          v-model="gr"
          :options="optionGR"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="code"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="gr == null && status_submit"
          >This field is required</span
        >
      </div>
      <div v-else class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Proof of GR Code"
          name="Proof of GR Code"
          v-model="gr_codes"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="External Number"
          name="External Number"
          v-model="external_number"
          placeholder="External Number"
        />
        <span
          class="text-danger text-sm"
          v-if="external_number == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div v-else class="vx-row mb-2">
      <div v-if="this.invoice_number == ''" class="vx-col w-1/2">
        <label class="vs-input--label">Proof of GR Code</label>
        <multiselect
          class="selectExample"
          v-model="gr"
          :options="optionGR"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="bast_code"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.bast_code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.bast_code }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="gr == null && status_submit"
          >This field is required</span
        >
      </div>
      <div v-else class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Proof of GR Code"
          name="Proof of GR Code"
          v-model="gr_codes"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="External Number"
          name="External Number"
          v-model="external_number"
          placeholder="External Number"
        />
        <span
          class="text-danger text-sm"
          v-if="external_number == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Proof of GR Date"
          name="Proof of GR Date"
          v-model="gr_dates"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="External Code"
          name="External Code"
          v-model="table.inv_code"
          placeholder="External Code"
        />
        <span
          class="text-danger text-sm"
          v-if="table.inv_code == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Barcode"
          name="Barcode"
          v-model="barcode"
          placeholder="Barcode"
        />
        <span
          class="text-danger text-sm"
          v-if="barcode == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Invoice Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="table.inv_date"
          placeholder="Select Invoice Date"
          format="yyyy-MM-dd"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="table.inv_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          class="selectExample"
          v-model="table.payment_term"
          :options="optionPaymentTerm"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="false"
          @input="handleChangePaymentTerm"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.payment_term == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="TOP"
          name="TOP"
          v-model="top"
          @keypress="isNumber($event)"
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Create Date</label>
        <datepicker
          class="w-full input-readonly"
          v-validate="'required'"
          :inline="false"
          name="Create Date"
          v-model="released_date"
          disabled
          placeholder="Create date"
          format="yyyy-MM-dd"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Received Date</label>
        <datepicker
          class="w-full input-readonly"
          v-validate="'required'"
          :inline="false"
          name="Received Date"
          v-model="received_date"
          disabled
          placeholder="Received date"
          format="yyyy-MM-dd"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2"></div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Invoice Due Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Due Date"
          v-model="due_date"
          placeholder="Due date"
          format="yyyy-MM-dd"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="due_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="currency_code"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-2 ml-1 mt-6">
      <h5>Line Item</h5>
    </div> -->
    <vs-divider style="width: 100%; margin-left: 0%"
      ><h5>Line Item GR</h5></vs-divider
    >
    <table
      style="z-index: 99 !important"
      class="vs-table vs-table--tbody-table"
    >
      <tr>
        <th>Item</th>
        <th width="6%">UOM</th>
        <th width="6%">Qty</th>
        <th>Unit Price</th>
        <th>Sub total</th>
        <th>Discount</th>
        <th>Net</th>
        <th>Tax</th>
        <th>Total</th>
        <template v-if="this.po_type == '1'">
          <th width="20%">WH Tax</th>
          <th>WH Tax Value</th>
        </template>
      </tr>
      <template v-for="(tr, indextr) in table.data">
        <tr
          class="tr-values vs-table--tr tr-table-state-null selected"
          v-bind:key="indextr"
        >
          <td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.item_name }}</span>
            </div>
            <div class="ml-0">
              <span v-if="tr.project" class="w-5/5"
                >Progress PO :
                {{
                  tr.progress_po
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                %<br />
              </span>
              <span v-if="tr.project" class="w-5/5"
                >Progress GR :
                {{
                  tr.progress_bast
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                %
              </span>
              <span v-else class="w-5/5"
                >Received :
                {{
                  tr.qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ tr.item_unit }}
              </span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="UOM"
                v-model="tr.item_unit"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Qty"
                v-model="tr.quantity"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <!-- <vs-input
                  v-validate="'required'"
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'price')"
                  @focus="$event.target.select()"
                  class="w-full"
                  name="Price Inv"
                  v-model="tr.price"
                /> -->
              <vs-input
                class="w-full input-readonly"
                name="Price Inv"
                v-model="tr.price"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Subtotal"
                v-model="tr.subtotal"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <!-- <vs-input
                  v-validate="'required'"
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'discount')"
                  @focus="$event.target.select()"
                  class="w-full"
                  name="Discount"
                  v-model="tr.discount"
                /> -->
              <vs-input
                class="w-full input-readonly"
                name="Discount"
                v-model="tr.discount"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Net"
                v-model="tr.net"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Tax"
                v-model="tr.tax"
                readonly
              />
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Total"
                v-model="tr.total"
                readonly
              />
            </div>
          </td>
          <template v-if="po_type == '1'">
            <td>
              <div class="vx-row ml-0">
                <multiselect
                  class="selectExample"
                  v-model="tr.wh_tax_object"
                  @input="handleWhTax(indextr)"
                  :options="optionWhTax"
                  :multiple="false"
                  :allow-empty="true"
                  :group-select="false"
                  placeholder=" Type to search"
                  track-by="Name"
                  :max-height="120"
                  label="Name"
                >
                  <template slot="singleLabel" slot-scope="dt">
                    <span class="option__desc">
                      <span v-if="!dt.option.Name" class="option__title">
                        Type to search
                      </span>
                      <span v-else class="option__title">
                        {{ dt.option.Name }}
                      </span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="dt">
                    <div class="option__desc">
                      <span class="option__title">
                        {{ dt.option.Name }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'wh_tax')"
                  @focus="$event.target.select()"
                  class="w-full"
                  name="Wh Tax"
                  v-model="tr.wh_tax"
                />
              </div>
            </td>
          </template>
        </tr>
      </template>
    </table>
    <!-- <div v-if="this.invoice_number == ''">
    <vs-divider style="width: 100%; margin-left: 0%"><h5>Line Item OCR</h5></vs-divider>
    <table style="z-index: 70 !important" class="vs-table vs-table--tbody-table">
      <tr>
        <th>Item</th>
        <th width="12%">Qty</th>
        <th>Price</th>
        <th>Sub total</th>
        <th>Discount</th>
        <th>Net</th>
        <th>Tax</th>
      </tr>
      <template v-for="(tr,index) in table.data_ocr">
        <tr class="tr-values vs-table--tr tr-table-state-null selected"
          v-bind:key="index"
          v-bind:class="{'matched': tr.isMatched}"
          >
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.item_name_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.quantity_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.price_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.subtotal_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.discount_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.net_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.tax_ocr }}</span>
            </div>
          </td>
        </tr>
      </template>
    </table>
  </div> -->

    <!-- <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div> -->
    <!-- <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'value')"
                @focus="$event.target.select()"
                class="w-full"
                name="Value"
                v-model="data[indextr].value"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].value == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <div style="display: none"> -->
    <!-- <p>{{ (tax_charges[indextr] = data[indextr].tax) }}</p> -->
    <!-- <p>{{ (charges[indextr] = data[indextr].value) }}</p> -->
    <!-- </div>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table> -->
    <vs-divider style="width: 100%; margin-left: 0%"
      ><h5>Summary</h5></vs-divider
    >
    <template v-if="po_type == '1'">
      <div
        class="vx-row w-5/5 mb-3 mt-6"
        style="margin-bottom: -3rem !important"
      >
        <vs-input
          class="w-1/5 input-readonly"
          label="Item Value GR"
          name="Item Value GR"
          v-model="table.item_value_gr"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly pl-1 pr-1"
          label="Charges Value GR"
          name="Charges Value GR"
          v-model="table.charges_value_gr"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly pl-1 pr-1"
          label="Tax Value GR"
          name="Tax Value GR"
          v-model="table.tax_value_gr"
          readonly
        />

        <!-- <vs-input
          class="w-1/6 input-readonly pr-1"
          label="Discount Value GR"
          name="Discount Value GR"
          v-model="table.discount_value_gr"
          readonly
        /> -->

        <vs-input
          class="w-1/5 input-readonly pr-1"
          label="Total Invoice Value GR"
          name="Total Invoice Value GR"
          v-model="table.total_inv_value_gr"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly"
          label="WH Tax Value GR"
          name="WH Tax Value GR"
          v-model="table.wh_tax_value_gr"
          readonly
        />
        <div
          style="
            width: 10%;
            position: relative;
            top: -50px;
            left: 98%;
            text-align: center;
          "
        >
          <p>SKB</p>
          <input
            type="checkbox"
            :checked="table.is_skb === 1"
            v-model="table.is_skb"
            style="height: 80%"
          />
        </div>
      </div>

      <div
        class="vx-row w-5/5 mb-3 mt-6"
        style="margin-bottom: -3rem !important"
      >
        <vs-input
          class="w-1/5 input-readonly"
          label="Item Value"
          name="Item Value"
          v-model="table.item_value"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly pl-1 pr-1"
          label="Charges Value"
          name="Charges Value"
          v-model="table.charges_value"
          readonly
        />

        <vs-input
          @keypress="isNumber($event)"
          @input="formatNumber(0, 'tax_value')"
          @focus="$event.target.select()"
          class="w-1/5 pr-1"
          label="Tax Value"
          name="Tax Value"
          v-model="table.tax_value"
        />

        <!-- <vs-input
          class="w-1/6 input-readonly pr-1"
          label="Discount Value"
          name="Discount Value"
          v-model="table.discount_value"
          readonly
        /> -->
        <vs-input
          @keypress="isNumber($event)"
          @input="formatNumber(0, 'total_invoice_value')"
          @focus="$event.target.select()"
          class="w-1/5 pr-1"
          label="Total Invoice Value"
          name="Total Invoice Value"
          v-model="table.total_inv_value"
        />

        <vs-input
          class="w-1/5 input-readonly"
          label="WH Tax Value"
          name="WH Tax Value"
          v-model="table.wh_tax_value"
          readonly
        />
        <div
          style="
            width: 10%;
            position: relative;
            top: -50px;
            left: 98%;
            text-align: center;
          "
        >
          <p>VAT Expired</p>
          <input
            type="checkbox"
            :checked="table.vat_expired === 1"
            v-model="table.vat_expired"
            style="height: 80%"
            @change="handleVatExpiredChange"
          />
        </div>
      </div>

      <div class="vx-row w-5/5 mb-3 mt-6">
        <vs-input
          class="w-1/5 input-readonly"
          label="Item Value Faktur"
          name="Item Value Faktur"
          v-model="table.item_value_faktur"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly pl-1 pr-1"
          label="Charges Value Faktur"
          name="Charges Value Faktur"
          v-model="table.charges_value_faktur"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly pl-1 pr-1"
          label="Tax Value Faktur"
          name="Tax Value Faktur"
          v-model="table.tax_faktur"
          readonly
        />

        <!-- <vs-input
          class="w-1/6 input-readonly pr-1"
          label="Discount Value GR"
          name="Discount Value GR"
          v-model="table.discount_value_gr"
          readonly
        /> -->

        <vs-input
          class="w-1/5 input-readonly pr-1"
          label="Total Invoice Value Faktur"
          name="Total Invoice Value Faktur"
          v-model="table.total_value_faktur"
          readonly
        />

        <vs-input
          class="w-1/5 input-readonly"
          label="WH Tax Value Faktur"
          name="WH Tax Value Faktur"
          v-model="table.wh_tax_value_faktur"
          readonly
        />
      </div>

      <!-- <div class="vx-row w-5/5 mb-3 mt-6"> -->
      <div class="vx-row w-5/5 mb-3 mt-6">
        <vs-input
          class="w-1/5 input-readonly"
          label="Difference Value"
          name="Difference Value"
          v-model="table.difference_value"
          readonly
        />
      </div>
    </template>
    <template v-else>
      <div class="vx-row w-4/4 mb-3 mt-6">
        <vs-input
          class="w-1/4 input-readonly"
          label="Item Value GR"
          name="Item Value GR"
          v-model="table.item_value_gr"
          readonly
        />
        <vs-input
          class="w-1/4 input-readonly pl-1 pr-1"
          label="Charges Value GR"
          name="Charges Value GR"
          v-model="table.charges_value_gr"
          readonly
        />
        <vs-input
          class="w-1/4 input-readonly pr-1"
          label="Tax Value GR"
          name="Tax Value GR"
          v-model="table.tax_value_gr"
          readonly
        />
        <!-- <vs-input
          class="w-1/5 input-readonly pr-1"
          label="Discount Value GR"
          name="Discount Value GR"
          v-model="table.discount_value_gr"
          readonly
        /> -->
        <vs-input
          class="w-1/4 input-readonly"
          label="Total Value GR"
          name="Total Value GR"
          v-model="table.total_inv_value_gr"
          readonly
        />
      </div>

      <div
        class="vx-row w-4/4 mb-3 mt-6"
        style="margin-bottom: -3rem !important"
      >
        <vs-input
          class="w-1/4 input-readonly"
          label="Item Value"
          name="Item Value"
          v-model="table.item_value"
          readonly
        />
        <vs-input
          class="w-1/4 input-readonly pl-1 pr-1"
          label="Charges Value"
          name="Charges Value"
          v-model="table.charges_value"
          readonly
        />
        <vs-input
          @keypress="isNumber($event)"
          @input="formatNumber(0, 'tax_value_trade')"
          @focus="$event.target.select()"
          class="w-1/4 pr-1"
          label="Tax Value"
          name="Tax Value"
          v-model="table.tax_value"
        />

        <!-- <vs-input
          class="w-1/5 input-readonly pr-1"
          label="Discount Value"
          name="Discount Value"
          v-model="table.discount_value"
          readonly
        /> -->
        <vs-input
          @keypress="isNumber($event)"
          @input="formatNumber(0, 'total_invoice_value')"
          @focus="$event.target.select()"
          label="Total Invoice Value"
          class="w-1/4"
          name="Total Invoice Value"
          v-model="table.total_inv_value"
        />
        <div
          style="
            width: 10%;
            position: relative;
            top: -50px;
            left: 98%;
            text-align: center;
          "
        >
          <p>VAT Expired</p>
          <input
            type="checkbox"
            :checked="table.vat_expired === 1"
            v-model="table.vat_expired"
            @change="handleVatExpiredChange"
          />
        </div>
      </div>

      <div class="vx-row w-4/4 mb-3 mt-6">
        <vs-input
          class="w-1/4 input-readonly"
          label="Item Value Faktur"
          name="Item Value Faktur"
          v-model="table.item_value_faktur"
          readonly
        />
        <vs-input
          class="w-1/4 input-readonly pl-1 pr-1"
          label="Charges Value Faktur"
          name="Charges Value Faktur"
          v-model="table.charges_value_faktur"
          readonly
        />
        <vs-input
          class="w-1/4 input-readonly pr-1"
          label="Tax Value Faktur"
          name="Tax Value Faktur"
          v-model="table.tax_faktur"
          readonly
        />
        <!-- <vs-input
          class="w-1/5 input-readonly pr-1"
          label="Discount Value"
          name="Discount Value"
          v-model="table.discount_value"
          readonly
        /> -->
        <vs-input
          class="w-1/4 input-readonly"
          label="Total Value Faktur"
          name="Total Value Faktur"
          v-model="table.total_value_faktur"
          readonly
        />
      </div>
      <div class="vx-row w-4/4 mb-3 mt-6">
        <vs-input
          class="w-1/4 input-readonly"
          label="Difference Value"
          name="Difference Value"
          v-model="table.difference_value"
          readonly
        />
      </div>
    </template>

    <vs-divider style="width: 100%; margin-left: 0%"></vs-divider>
    <div class="vx-row mb-6">
      <div class="vx-col w-full mb-6">
        <table width="100%" class="vs-table vs-table--tbody-table mb-6">
          <thead class="vs-table--thead">
            <tr>
              <th width="5%">Chart Of Account</th>
              <th width="5%">Cost Center</th>
              <th width="5%">Amount</th>
              <th width="14%">Description</th>
              <th width="3%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(invoiceCoa, index) in invoiceCoas"
              :key="index"
              style="padding-bottom: 5px"
            >
              <td class="td vs-table--td" style="padding: 5px">
                <CustomSelectCOA
                  :value="invoiceCoa.coa"
                  :base-url="'/api/v1/master/chart-of-account'"
                  :custom-label="codeWithName"
                  track-by="ID"
                  @selected="
                    (v) => {
                      invoiceCoa.coa = v;
                      invoiceCoa.coa_id = v.ID;
                      invoiceCoa.coa_code = v.Code;
                      invoiceCoa.coa_name = v.Name;
                    }
                  "
                >
                </CustomSelectCOA>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <CustomSelectCC
                  :value="invoiceCoa.cc"
                  :base-url="'/api/v1/master/cost-center'"
                  :custom-label="codeWithName"
                  track-by="ID"
                  @selected="
                    (v) => {
                      invoiceCoa.cc = v;
                      invoiceCoa.cc_id = v.ID;
                      invoiceCoa.cc_code = v.Code;
                      invoiceCoa.cc_name = v.Name;
                    }
                  "
                >
                </CustomSelectCC>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input
                  type="text"
                  v-model="invoiceCoa.amount"
                  class="w-full"
                  @input="validateAmount(index)"
                ></vs-input>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-input
                  type="text"
                  v-model="invoiceCoa.description"
                  class="w-full"
                ></vs-input>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <button
                  @click="removeItem(index)"
                  v-if="index !== 0"
                  class="remove-button"
                >
                  &#45;
                </button>
                <button @click="addItem(index)" v-else class="add-button">
                  &#43;
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col" style="width: 80%">
        <label class="vs-input--label">Refer to Role</label>
        <multiselect
          class="selectExample"
          v-model="selectedRole"
          :options="optionRoleRefer"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="display_name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.display_name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.display_name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col" style="margin-top: 20px">
        <vs-button @click="handleReferTo">Send to refer</vs-button>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">Notes</vs-divider>
    <template v-for="note in notes">
      <b v-bind:key="note"
        >{{ note }}
        <br />
      </b>
    </template>
    <br />
    <vs-textarea v-model="new_notes" />
    <vs-divider style="width: 100%; margin-left: 0%">Attachment</vs-divider>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col w-full">
        <label class="vs-input--label">Type</label>
        <multiselect
          class="selectExample"
          v-model="attachment"
          :options="optionAttachmentType"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="DocType"
          :disabled="false"
        >
        </multiselect>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png, .pdf"
            multiple
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.doc_type }}</td>
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="previewAttachment(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <vx-tooltip text="Download" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-download"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%"></vs-divider>

    <vs-popup title="Preview" :active.sync="showAttachment" :fullscreen="true">
      <iframe
        v-if="showAttachment"
        :src="attachmentPath"
        style="width: 100%; height: 80dvh"
      />
    </vs-popup>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button
          v-if="
            this.invoice_number == '' &&
            $store.getters['user/hasPermissions']('create')
          "
          class="mr-3 mb-2"
          @click="handleCreate"
          >Create</vs-button
        >
        <vs-button
          v-if="$store.getters['user/hasPermissions']('edit')"
          class="mr-3 mb-2"
          @click="handleUpdate"
          >Update</vs-button
        >
      </div>
    </div>
    <div>
      <vs-prompt
        color="primary"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doReferTo"
        @close="closeReferTo"
        :active.sync="activePromptReferTo"
      >
        <div class="con-exemple-prompt">
          Are you sure you want to refer this document '{{ this.barcode }}' to
          the {{ this.selectedRole ? this.selectedRole.name : "" }} role ?
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import CustomSelectCC from "./customselectCC.vue";
import CustomSelectCOA from "./customselectCOA.vue";

export default {
  components: {
    Datepicker,
    CustomSelectCC,
    CustomSelectCOA,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    project: {
      type: Boolean,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    customLabel(option) {
      return `${option.warehouse_code} - ${option.warehouse_name}`;
    },
    handleVatExpiredChange() {
      if (this.table.vat_expired) {
        let itemValGr = parseFloat(
          this.table.item_value_gr.toString().replace(/,/g, "")
        );
        this.table.total_inv_value = itemValGr
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.tax_value = (0)
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        let taxValOld = parseFloat(
          this.table.tax_value_old.toString().replace(/,/g, "")
        );
        let totValOld = parseFloat(
          this.table.total_inv_value_old.toString().replace(/,/g, "")
        );
        this.table.total_inv_value = totValOld
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.tax_value = taxValOld
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      let amounts = this.invoiceCoas
        .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
        .filter((value) => !isNaN(value));
      let totalAmount = amounts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      let itemValGr = parseFloat(
        this.table.item_value_gr.toString().replace(/,/g, "")
      );
      let taxVal = parseFloat(
        this.table.tax_value.toString().replace(/,/g, "")
      );
      let totVal = parseFloat(
        this.table.total_inv_value.toString().replace(/,/g, "")
      );
      let chargesVal = parseFloat(
        this.table.charges_value.toString().replace(/,/g, "")
      );
      let itemVal = totVal - taxVal - chargesVal;

      this.table.item_value = itemVal
        .toFixed(2)
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      var selisih = itemValGr + taxVal - totVal;
      console.log("SELISIH", selisih);
      if (!isNaN(totalAmount)) {
        var selisih_final = selisih + totalAmount;
        this.table.difference_value = selisih_final
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.table.difference_value = selisih
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    validateAmount(index) {
      const allowedChars = /[0-9-]/;
      const newValue = this.invoiceCoas[index].amount
        .split("")
        .filter((char) => allowedChars.test(char))
        .join("");
      this.$set(this.invoiceCoas, index, {
        ...this.invoiceCoas[index],
        amount: newValue,
      });

      if (this.invoiceCoas[index].amount.replace(/,/g, "").length < 16) {
        var str7 = this.table.tax_value.toString().split(".");
        if (str7.length > 2) {
          this.invoiceCoas[index].amount = this.invoiceCoas[index].amount
            .toString()
            .replace(/\.+$/, "");
        }

        if (str7.length == 2) {
          if (str7.length > 3) {
            this.invoiceCoas[index].amount = this.invoiceCoas[index].amount
              .toString()
              .slice(0, -1);
          }
        }

        this.invoiceCoas[index].amount = this.invoiceCoas[index].amount
          .replace(/,/g, "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.invoiceCoas[index].amount = this.invoiceCoas[index].amount
          .toString()
          .slice(0, -1);
      }
      //new
      let amounts = this.invoiceCoas
        .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
        .filter((value) => !isNaN(value));
      let totalAmount = amounts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      let itemValGr = parseFloat(
        this.table.item_value_gr.toString().replace(/,/g, "")
      );
      let taxVal = parseFloat(
        this.table.tax_value.toString().replace(/,/g, "")
      );
      let totVal = parseFloat(
        this.table.total_inv_value.toString().replace(/,/g, "")
      );
      let chargesVal = parseFloat(
        this.table.charges_value.toString().replace(/,/g, "")
      );
      let itemVal = totVal - taxVal - chargesVal;

      this.table.item_value = itemVal
        .toFixed(2)
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      var selisih = itemValGr + taxVal - totVal;
      console.log("SELISIH", selisih);
      if (!isNaN(totalAmount)) {
        var selisih_final = selisih + totalAmount;
        this.table.difference_value = selisih_final
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.table.difference_value = selisih
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    codeWithName({ Name, Code }) {
      if (Name != undefined) {
        return `${Code} - ${Name}`;
      }
      return `${Code}`;
    },
    codeWithSupplier({
      bank_name,
      account_name,
      account_number,
      territory_code,
      territory_name,
    }) {
      return `${bank_name} - ${account_name} - ${account_number} - ${territory_code} - ${territory_name}`;
    },
    initialState() {
      return {
        showAttachment: false,
        attachmentPath: "",
        attachment: null,
        attachment_type: "",
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        statusItemDouble: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          gl_account: null,
          bank: {},
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value_old: 0,
          total_inv_value: 0,
          total_inv_value_gr: 0,
          total_value_faktur: 0,
          item_value: 0,
          item_value_gr: 0,
          item_value_faktur: 0,
          discount_value: 0,
          discount_value_gr: 0,
          charges_value: 0,
          charges_value_gr: 0,
          charges_value_faktur: 0,
          tax_value_old: 0,
          tax_value: 0,
          tax_value_gr: 0,
          tax_faktur: 0,
          wh_tax_value_gr: 0,
          wh_tax_value_faktur: 0,
          difference_value: 0,
          amount: 0,
          data: [],
          data_ocr: [],
          data_charges: [],
          file: [],
          vat_expired: 0,
          is_skb: 0,
        },
        // tax_item: [],
        // tax_charges: [],
        create_date: null,
        released_date: null,
        invoice_number: "",
        external_number: "",
        top: 0,
        invoice_type: "",
        trade_invoice: "",
        tax_from_value: 0,
        po_type: "",
        barcode: "",
        gr_dates: "",
        supplier_name: "",
        supplier_code: "",
        new_notes: "",
        notes: "",
        gr_codes: "",
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
        file: {},
        total_discount: 0,
        type_submit: "",
        tr_number: null,
        tr_date: null,
        due_date: null,
        due_date_ori: null,
        invoice_baseline: null,
        received_date: null,
        dn_date: null,
        supplier: [{ ID: 99999 }],
        supplier_contact_name: null,
        supplier_phone: null,
        supplier_address: null,
        warehouse: null,
        warehouse_name: "",
        po: null,
        // po: null,
        po_code: null,
        po_id: null,
        po_date: null,
        gr_ids: null,
        gr: null,
        optionAttachmentType: [],
        optionSupplier: [],
        optionWarehouse: [],
        optionPaymentTerm: [],
        optionGlAccount: [],
        optionSupplierAccountName: [],
        optionPO: [],
        optionPO2: [],
        optionGR: [],
        grOrderNumber: "",
        bast_ids: "",
        currency_code: "",
        po_code_external: "",
        attachment_ids: [],
        oldValue: [],
        newValue: [],
        invoiceCoas: [{ coa: null, cc: null, amount: "", description: "" }],
        optionRoleRefer: [],
        selectedRole: null,
        activePromptReferTo: false,
        territory_id: 0,
      };
    },
    handleReferTo() {
      this.activePromptReferTo = true;
    },
    closeReferTo() {
      this.activePromptReferTo = false;
    },
    doReferTo() {
      this.$vs.loading();
      let param = {
        pi_id: this.piId,
        refer_to: this.selectedRole.id,
        notes: this.new_notes,
      };
      this.$http
        .post("/api/v1/pi/refer-to", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    addItem() {
      this.invoiceCoas.push({
        coa: null,
        cc: null,
        amount: "",
        description: "",
      });
    },
    removeItem(index) {
      if (this.invoiceCoas.length > 1) {
        this.invoiceCoas.splice(index, 1);
      }
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }

      if (charCode != 8 && this.tr_number != null) {
        if (this.tr_number.length == 3) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length == 7) {
          this.tr_number = this.tr_number + "-";
        } else if (this.tr_number.length == 10) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length > 18) {
          evt.preventDefault();
        }
      }
    },
    getRefferRole() {
      this.$vs.loading();
      this.$http.get("/api/v1/pi/get-ap-role").then((resp) => {
        this.optionRoleRefer = resp.data.map((role) => ({
          display_name: role.DisplayName,
          name: role.Name,
          id: role.ID,
        }));
        this.$vs.loading.close();
      });
    },
    getOptionAccountBank(supplier_id, bankIDSelected = 0) {
      this.optionSupplierAccountName = [];
      let warehouse_id =
        this.warehouse == null ? 0 : this.warehouse.warehouse_id;
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account-with-territory/" + supplier_id, {
          params: {
            warehouse_id: warehouse_id,
            territory_id: this.territory_id,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          resp.data.account.map((v) => {
            const newOption = {
              bank_name: v.BankName,
              bank_id: v.BankID,
              account_name: v.AccountName,
              account_number: v.AccountNumber,
              bank_branch: "Branch " + v.BankBranch,
              territory_name: v.name,
              territory_code: v.code,
              territory_id: v.TerritoryID,
              track_by: v.TerritoryID + "-" + v.BankID + "-" + v.AccountNumber,
            };
            this.optionSupplierAccountName.push(newOption);
            if (bankIDSelected != 0 && bankIDSelected != "") {
              if (
                v.BankID == bankIDSelected &&
                v.TerritoryID == this.territory_id
              ) {
                this.table.bank = {
                  bank_name: v.BankName,
                  bank_id: v.BankID,
                  account_name: v.AccountName,
                  account_number: v.AccountNumber,
                  bank_branch: "Branch " + v.BankBranch,
                  territory_name: v.name,
                  territory_code: v.code,
                  territory_id: v.TerritoryID,
                  track_by:
                    v.TerritoryID + "-" + v.BankID + "-" + v.AccountNumber,
                };
              }
            } else {
              if (v.is_default) {
                this.table.bank = {
                  bank_name: v.BankName,
                  bank_id: v.BankID,
                  account_name: v.AccountName,
                  account_number: v.AccountNumber,
                  bank_branch: "Branch " + v.BankBranch,
                  territory_name: v.name,
                  territory_code: v.code,
                  territory_id: v.TerritoryID,
                  track_by:
                    v.TerritoryID + "-" + v.BankID + "-" + v.AccountNumber,
                };
              }
            }
          });
          this.$vs.loading.close();
        });
    },
    getDueInvoicePeriod(supplier_id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/supplier-get-payment", {
          params: {
            id: supplier_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (
              this.released_date &&
              this.released_date !== "0001-01-01T00:00:00Z"
            ) {
              const releasedDate = new Date(this.released_date);
              // console.log("Initial releasedDate:", releasedDate);

              const dueInvoicePeriod = parseInt(
                resp.data[0].due_invoice_period
              );
              if (!isNaN(dueInvoicePeriod)) {
                releasedDate.setDate(releasedDate.getDate() + dueInvoicePeriod);
                this.due_date = releasedDate.toISOString().split("T")[0]; // Format yyyy-MM-dd
                this.due_date_ori = this.due_date;
                // console.log("Calculated due_date:", this.due_date);
                // console.log("Due date ori:", this.due_date_ori);
              } else {
                console.error(
                  "Invalid due_invoice_period:",
                  resp.data.due_invoice_period
                );
              }
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPaymentTerm = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionGlAccount() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionGlAccount = resp.data.records
              .map((record) => {
                if (record.coa) {
                  return {
                    id: record.coa.ID,
                    name: record.coa.Name,
                    code: record.coa.Code,
                  };
                } else {
                  return null; // Atau cara lain untuk menangani record tanpa coa
                }
              })
              .filter((record) => record !== null);
            // this.optionGlAccount = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
    },
    handleAttachment() {
      if (this.$refs.file.files[0]) {
        let a = this.$refs.file.files;
        for (let i = 0; i < a.length; i++) {
          if (a[i].size <= 5000000) {
            // 5mb
            this.file = a[i];
            this.uploadData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: a[i].name + " is greater than 5MB",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "No file chosen.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleUpdate() {
      this.status_submit = true;
      this.type_submit = "update";
      // this.$vs.loading();
      this.putData();
    },
    handleCreate() {
      this.status_submit = true;
      this.type_submit = "create";
      this.postData();
      // mix2-10519 disable validasi item double
      // if (this.statusItemDouble == false) {
      //   // this.$vs.loading();
      // } else {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Please check line item.",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-check",
      //   });
      // }
    },
    formatNumber(index, status) {
      if (status === "qty") {
        if (this.table.data[index].quantity.replace(/,/g, "").length < 16) {
          var str = this.table.data[index].quantity.toString().split(".");
          if (str.length > 2) {
            this.table.data[index].quantity = this.table.data[index].quantity
              .toString()
              .replace(/\.+$/, "");
          }

          if (str.length == 2) {
            if (str[1].length > 3) {
              this.table.data[index].quantity = this.table.data[index].quantity
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].quantity = this.table.data[index].quantity
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.data[index].quantity = this.table.data[index].quantity
            .toString()
            .slice(0, -1);
        }
      } else if (status === "price") {
        if (this.table.data[index].price.replace(/,/g, "").length < 16) {
          var str1 = this.table.data[index].price.toString().split(".");
          if (str1.length > 2) {
            this.table.data[index].price = this.table.data[index].price
              .toString()
              .replace(/\.+$/, "");
          }

          if (str1.length == 2) {
            if (str1[1].length > 3) {
              this.table.data[index].price = this.table.data[index].price
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].price = this.table.data[index].price
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.price[index] = this.table.data[index].price;
          this.cal();
        } else {
          this.table.data[index].price = this.table.data[index].price
            .toString()
            .slice(0, -1);
        }
      } else if (status === "value") {
        if (
          this.table.data_charges[index].value.replace(/,/g, "").length < 16
        ) {
          var str2 = this.table.data_charges[index].value.toString().split(".");
          if (str2.length > 2) {
            this.table.data_charges[index].value = this.table.data_charges[
              index
            ].value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str2.length == 2) {
            if (str2[1].length > 3) {
              this.table.data_charges[index].value = this.table.data_charges[
                index
              ].value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.charges[index] = this.table.data_charges[index].value;

          this.cal();
        } else {
          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax") {
        // if (this.table.data_charges[index].tax.replace(/,/g, "").length < 16) {
        //   var str3 = this.table.data_charges[index].tax.toString().split(".");
        //   if (str3.length > 2) {
        //     this.table.data_charges[index].tax = this.table.data_charges[
        //       index
        //     ].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str3.length == 2) {
        //     if (str3[1].length > 3) {
        //       this.table.data_charges[index].tax = this.table.data_charges[
        //         index
        //       ].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data_charges[index].tax = Number(
        //     this.table.data_charges[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_charges[index] = this.table.data_charges[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data_charges[index].tax = this.table.data_charges[
        //     index
        //   ].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "tax-item") {
        // if (this.table.data[index].tax.replace(/,/g, "").length < 16) {
        //   var str4 = this.table.data[index].tax.toString().split(".");
        //   if (str4.length > 2) {
        //     this.table.data[index].tax = this.table.data[index].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str4.length == 2) {
        //     if (str4[1].length > 3) {
        //       this.table.data[index].tax = this.table.data[index].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data[index].tax = Number(
        //     this.table.data[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_item[index] = this.table.data[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data[index].tax = this.table.data[index].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "discount") {
        if (this.table.data[index].discount.replace(/,/g, "").length < 16) {
          var str5 = this.table.data[index].discount.toString().split(".");
          if (str5.length > 2) {
            this.table.data[index].discount = this.table.data[index].discount
              .toString()
              .replace(/\.+$/, "");
          }

          if (str5.length == 2) {
            if (str5[1].length > 3) {
              this.table.data[index].discount = this.table.data[index].discount
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].discount = this.table.data[index].discount
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.discount[index] = this.table.data[index].discount;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax_value") {
        if (this.table.tax_value.replace(/,/g, "").length < 16) {
          var str6 = this.table.tax_value.toString().split(".");
          if (str6.length > 2) {
            this.table.tax_value = this.table.tax_value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str6.length == 2) {
            if (str6.length > 3) {
              this.table.tax_value = this.table.tax_value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.tax_value = this.table.tax_value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.tax_value = this.table.tax_value.toString().slice(0, -1);
        }
        //new
        let amounts = this.invoiceCoas
          .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
          .filter((value) => !isNaN(value));
        let totalAmount = amounts.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        let itemValGr = parseFloat(
          this.table.item_value_gr.toString().replace(/,/g, "")
        );
        let taxVal = parseFloat(
          this.table.tax_value.toString().replace(/,/g, "")
        );
        let totVal = parseFloat(
          this.table.total_inv_value.toString().replace(/,/g, "")
        );
        let chargesVal = parseFloat(
          this.table.charges_value.toString().replace(/,/g, "")
        );
        let itemVal = totVal - taxVal - chargesVal;

        this.table.item_value = itemVal
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        var selisih = itemValGr + taxVal - totVal;
        console.log("SELISIH", selisih);
        if (!isNaN(totalAmount)) {
          var selisih_final = selisih + totalAmount;
          this.table.difference_value = selisih_final
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.difference_value = selisih
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else if (status === "tax_value_trade") {
        if (this.table.tax_value.replace(/,/g, "").length < 16) {
          var str7 = this.table.tax_value.toString().split(".");
          if (str7.length > 2) {
            this.table.tax_value = this.table.tax_value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str7.length == 2) {
            if (str7.length > 3) {
              this.table.tax_value = this.table.tax_value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.tax_value = this.table.tax_value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.tax_value = this.table.tax_value.toString().slice(0, -1);
        }
        //new
        let amounts = this.invoiceCoas
          .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
          .filter((value) => !isNaN(value));
        let totalAmount = amounts.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        let itemValGr = parseFloat(
          this.table.item_value_gr.toString().replace(/,/g, "")
        );
        let taxVal = parseFloat(
          this.table.tax_value.toString().replace(/,/g, "")
        );
        let totVal = parseFloat(
          this.table.total_inv_value.toString().replace(/,/g, "")
        );
        let chargesVal = parseFloat(
          this.table.charges_value.toString().replace(/,/g, "")
        );
        let itemVal = totVal - taxVal - chargesVal;

        this.table.item_value = itemVal
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        var selisih_trade = itemValGr + taxVal - totVal;
        console.log("SELISIH", selisih_trade);
        if (!isNaN(totalAmount)) {
          var selisih_final_trade = selisih_trade + totalAmount;
          this.table.difference_value = selisih_final_trade
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.difference_value = selisih_trade
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else if (status === "wh_tax") {
        if (this.table.data[index].wh_tax.replace(/,/g, "").length < 16) {
          var str8 = this.table.data[index].wh_tax.toString().split(".");
          if (str8.length > 2) {
            this.table.data[index].wh_tax = this.table.data[index].wh_tax
              .toString()
              .replace(/\.+$/, "");
          }

          if (str8.length == 2) {
            if (str8[1].length > 3) {
              this.table.data[index].wh_tax = this.table.data[index].wh_tax
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].wh_tax = this.table.data[index].wh_tax
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.net[index] = this.table.data[index].net;
          this.cal();
        } else {
          this.table.data[index].wh_tax = this.table.data[index].wh_tax
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax_from_value") {
        if (this.tax_from_value.replace(/,/g, "").length < 16) {
          var str9 = this.tax_from_value.toString().split(".");
          if (str9.length > 2) {
            this.tax_from_value = this.tax_from_value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str9.length == 2) {
            if (str9.length > 3) {
              this.tax_from_value = this.tax_from_value.toString().slice(0, -1);
            }
          }

          this.tax_from_value = this.tax_from_value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.tax_from_value = this.tax_from_value.toString().slice(0, -1);
        }
      } else if (status === "total_invoice_value") {
        if (this.table.total_inv_value.replace(/,/g, "").length < 16) {
          var str10 = this.table.total_inv_value.toString().split(".");
          if (str10.length > 2) {
            this.table.total_inv_value = this.table.total_inv_value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str10.length == 2) {
            if (str10.length > 3) {
              this.table.total_inv_value = this.table.total_inv_value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.total_inv_value = this.table.total_inv_value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.total_inv_value = this.table.total_inv_value
            .toString()
            .slice(0, -1);
        }
        //new
        let amounts = this.invoiceCoas
          .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
          .filter((value) => !isNaN(value));
        let totalAmount = amounts.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        let itemValGr = parseFloat(
          this.table.item_value_gr.toString().replace(/,/g, "")
        );
        let taxVal = parseFloat(
          this.table.tax_value.toString().replace(/,/g, "")
        );
        let totVal = parseFloat(
          this.table.total_inv_value.toString().replace(/,/g, "")
        );
        let chargesVal = parseFloat(
          this.table.charges_value.toString().replace(/,/g, "")
        );
        let itemVal = totVal - taxVal - chargesVal;

        this.table.item_value = itemVal
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        var selisih1 = itemValGr + taxVal - totVal;
        console.log("SELISIH", selisih1);
        if (!isNaN(totalAmount)) {
          var selisih_final1 = selisih1 + totalAmount;
          this.table.difference_value = selisih_final1
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.difference_value = selisih1
            .toFixed(2)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    cal() {
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
      let f = 0; // wh tax
      let tax_received = 0;
      let net_received = 0;
      let discount_received = 0;

      // calculate tax item
      console.log("DATAAAA", this.table.data);
      if (this.po_type == "0") {
        // trade
        this.table.data.map((r, i) => {
          this.table.data[i].subtotal = Math.round(
            parseFloat(this.table.data[i].quantity) *
              parseFloat(this.table.data[i].price.toString().replace(/,/g, ""))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.table.data[i].net = Math.round(
            parseFloat(
              this.table.data[i].subtotal.toString().replace(/,/g, "")
            ) -
              parseFloat(
                this.table.data[i].discount.toString().replace(/,/g, "")
              )
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.table.data[i].total = parseFloat(
            parseFloat(this.table.data[i].net.toString().replace(/,/g, "")) +
              parseFloat(this.table.data[i].tax.toString().replace(/,/g, ""))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          tax_received += parseFloat(this.table.data[i].tax_received);
          discount_received += parseFloat(this.table.data[i].discount_received);
          net_received += parseFloat(this.table.data[i].net_received);
          a += parseFloat(this.table.data[i].tax.toString().replace(/,/g, ""));
          e += parseFloat(
            this.table.data[i].discount.toString().replace(/,/g, "")
          );
          d += parseFloat(this.table.data[i].net.toString().replace(/,/g, ""));

          this.oldValue[i] = this.newValue[i];
        });
      } else {
        this.table.data.map((r, i) => {
          this.table.data[i].tax = Math.floor(
            (parseFloat(this.table.data[i].tax_rate) / 100) *
              (parseFloat(this.table.data[i].net.replace(/,/g, "")) -
                parseFloat(
                  this.table.data[i].discount.toString().replace(/,/g, "")
                ))
          );
          this.table.data[i].subtotal = Math.round(
            parseFloat(this.table.data[i].quantity) *
              parseFloat(this.table.data[i].price.toString().replace(/,/g, ""))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.table.data[i].net = Math.round(
            parseFloat(
              this.table.data[i].subtotal.toString().replace(/,/g, "")
            ) -
              parseFloat(
                this.table.data[i].discount.toString().replace(/,/g, "")
              )
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.table.data[i].total = parseFloat(
            parseFloat(this.table.data[i].net.toString().replace(/,/g, "")) +
              parseFloat(this.table.data[i].tax.toString().replace(/,/g, ""))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          tax_received += parseFloat(this.table.data[i].tax);
          discount_received += parseFloat(this.table.data[i].discount);
          net_received += parseFloat(
            this.table.data[i].net.toString().replace(/,/g, "")
          );
          a += this.table.data[i].tax;
          if (typeof this.table.data[i].wh_tax === "string") {
            f += Math.floor(
              parseFloat(this.table.data[i].wh_tax.replace(/,/g, ""))
            );
          } else {
            f += Math.floor(this.table.data[i].wh_tax);
          }
          e += parseFloat(
            this.table.data[i].discount.toString().replace(/,/g, "")
          );
          d += parseFloat(this.table.data[i].net.toString().replace(/,/g, ""));
          this.table.data[i].tax = this.table.data[i].tax
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.oldValue[i] = this.newValue[i];
        });
      }
      console.log("tax", tax_received);
      // calculate tax charge
      this.table.data_charges.map((r, i) => {
        this.table.data_charges[i].tax =
          (this.table.data_charges[i].tax_rate / 100) *
          parseInt(this.table.data_charges[i].value.replace(/,/g, ""));
        b += this.table.data_charges[i].tax;
        c += parseFloat(
          this.table.data_charges[i].value.toString().replace(/,/g, "")
        );
        this.table.data_charges[i].tax = this.table.data_charges[i].tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // this.$nextTick(() => {
      console.log("Before setting wh_tax_value:", this.table.wh_tax_value);
      this.table.tax_value = Math.floor(a) // tax invoice & tax charge
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.tax_value_gr = Math.floor(tax_received)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.tax_value_ori = parseInt(a + b)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.charges_value = c
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.charges_value_gr = c
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // this.table.item_value = d
      //   .toFixed(2)
      //   .toString()
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.item_value_gr = d
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.discount_value = e
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.discount_value_gr = discount_received
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.po_type == "0") {
        this.table.total_inv_value_gr = (parseInt(Math.floor(tax_received)) + d)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.table.total_inv_value_gr = (parseInt(tax_received) + d)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        console.log("WH TAX", f);
        this.table.wh_tax_value = f
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        console.log("After setting wh_tax_value:", this.table.wh_tax_value);
      }
      //new
      let amounts = this.invoiceCoas
        .map((item) => parseFloat(item.amount.toString().replace(/,/g, "")))
        .filter((value) => !isNaN(value));
      let totalAmount = amounts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      let itemValGr = parseFloat(
        this.table.item_value_gr.toString().replace(/,/g, "")
      );
      let taxVal = parseFloat(
        this.table.tax_value.toString().replace(/,/g, "")
      );
      let totVal = parseFloat(
        this.table.total_inv_value.toString().replace(/,/g, "")
      );
      let selisih1 = itemValGr + taxVal - totVal;
      console.log("SELISIH", selisih1);
      if (!isNaN(totalAmount)) {
        let selisih_final1 = selisih1 + totalAmount;
        this.table.difference_value = selisih_final1
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.table.difference_value = selisih1
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      // });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      const totalInvValue = this.table.total_inv_value
        ? this.table.total_inv_value
        : 0;
      const taxValue = this.table.tax_value ? this.table.tax_value : 0;
      console.log("total invoice value", totalInvValue.toString());
      console.log("item value", this.table.item_value.toString());
      console.log("charge value", this.table.charges_value.toString());
      console.log("discount value", this.table.discount_value.toString());
      console.log("po id", this.po_id);
      return {
        pi_id: this.piId,
        supplier_address: this.supplier_address,
        supplier_name: this.supplier_name,
        supplier_contact_name: this.supplier_contact_name,
        supplier_phone: this.supplier_phone,
        supplier_id: this.supplier.ID,
        warehouse_name: this.warehouse.warehouse_name,
        warehouse_id: this.warehouse.warehouse_id,
        warehouse_code: this.warehouse.warehouse_code,
        inv_code: this.table.inv_code, //
        inv_date: moment(this.table.inv_date).add(7, "h"), //
        due_invoice_period: this.table.payment_term.due_invoice_period, //
        reference_purchase_code: this.po_code,
        operating_unit_id: this.po[0].operating_unit_id,
        reference_purchase_id: this.po_id, // belum dipakai
        currency_code: this.po[0].currency_code,
        po_date: this.po_date,
        po_posting_date: this.po[0].posting_date, // belum dipakai
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        payment_term_id: this.table.payment_term.id,
        payment_term_name: this.table.payment_term.name, //
        payment_term_name_po: this.payment_term_name_po, //
        bank_name: this.table.bank.bank_name, //
        bank_id: this.table.bank.bank_id, //
        account_name: this.table.bank.account_name, //
        account_number: this.table.bank.account_number, //
        total_inv_value: totalInvValue.toString(),
        tax_value: taxValue.toString(),
        item_value: this.table.item_value.toString(), //
        charge_value: this.table.charges_value.toString(), //
        data_charge: this.table.data_charges, //
        total_discount: this.table.discount_value, //
        total_discount_po: this.po[0].discount, // belum dipakai
        total_po_value: this.po[0].total, // belum dipakai
        status_submit: this.type_submit, //
        tr_number: this.tr_number, //
        dn_date: this.dn_date,
        received_date: this.received_date,
        delivery_order_number: this.grOrderNumber,
        tr_date:
          this.tr_date == ""
            ? moment(String("0001-01-01T15:04:05Z07:00"))
            : this.tr_date, //
        due_date: moment(this.due_date).add(7, "h"),
        bast_ids: this.bast_ids,
        gr_ids: this.gr_ids,
        gr_codes: this.gr_codes,
        gr_dates: this.gr_dates,
        po_code_external: this.po_code_external,
        notes: this.new_notes,
        attachment_ids: this.attachment_ids,
        nt_type: this.table.project == true ? "1" : "0",
        data_line: this.table.data,
        barcode: this.barcode,
        po_type: this.po_type,
        // tax_from_value: this.tax_from_value.toString(),
        top: parseInt(this.top),
        data_coas: this.invoiceCoas,
        vat_expired: this.table.vat_expired == true ? 1 : 0,
        is_skb: this.table.is_skb == true ? 1 : 2,
        refer_to: this.selectedRole ? this.selectedRole.id : 0,
      };
    },
    paramPutData() {
      const totalInvValue = this.table.total_inv_value
        ? this.table.total_inv_value
        : 0;
      const taxValue = this.table.tax_value ? this.table.tax_value : 0;
      return {
        pi_id: this.piId,
        supplier_address: this.supplier_address,
        supplier_name: this.supplier_name,
        supplier_contact_name: this.supplier_contact_name,
        supplier_phone: this.supplier_phone,
        supplier_id: this.supplier.ID,
        inv_code: this.table.inv_code, //
        inv_date: moment(this.table.inv_date).add(7, "h"), //
        due_invoice_period: this.table.payment_term.due_invoice_period, //
        reference_purchase_code: this.po_code,
        reference_purchase_id: this.po_id, // belum dipakai
        po_date: this.po_date,
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        payment_term_id: this.table.payment_term.id,
        payment_term_name: this.table.payment_term.name, //
        payment_term_name_po: this.payment_term_name_po, //
        bank_name: this.table.bank.bank_name, //
        bank_id: this.table.bank.bank_id, //
        account_name: this.table.bank.account_name, //
        account_number: this.table.bank.account_number, //
        total_inv_value: totalInvValue.toString(),
        tax_value: taxValue.toString(),
        item_value: this.table.item_value.toString(), //
        charge_value: this.table.charges_value.toString(), //
        data_charge: this.table.data_charges, //
        total_discount: this.table.discount_value, //
        status_submit: this.type_submit, //
        tr_number: this.tr_number, //
        dn_date: this.dn_date,
        received_date: this.received_date,
        delivery_order_number: this.grOrderNumber,
        tr_date:
          this.tr_date == ""
            ? moment(String("0001-01-01T15:04:05Z07:00"))
            : this.tr_date, //
        due_date: moment(this.due_date).add(7, "h"),
        bast_ids: this.bast_ids,
        gr_codes: this.gr_codes,
        gr_dates: this.gr_dates,
        po_code_external: this.po_code_external,
        notes: this.new_notes,
        attachment_ids: this.attachment_ids,
        nt_type: this.table.project == true ? "1" : "0",
        data_line: this.table.data,
        barcode: this.barcode,
        top: parseInt(this.top),
        data_coas: this.invoiceCoas,
        vat_expired: this.table.vat_expired == true ? 1 : 0,
        is_skb: this.table.is_skb == true ? 1 : 2,
        refer_to: this.selectedRole ? this.selectedRole.id : 0,
      };
    },
    postData() {
      let itemValGr = parseFloat(
        this.table.item_value_gr.toString().replace(/,/g, "")
      );
      let taxVal = parseFloat(
        this.table.tax_value.toString().replace(/,/g, "")
      );
      let totVal = parseFloat(
        this.table.total_inv_value.toString().replace(/,/g, "")
      );
      var selisih = itemValGr + taxVal - totVal;
      var valueCoas = 0;
      let errorMessages = [];
      this.invoiceCoas.forEach((el, index) => {
        console.log("COA", el);
        if (el.coa != null && el.coa_code.startsWith("7") && el.cc == null) {
          errorMessages.push(`Cost Center is required.`);
        } else if (el.coa == null && selisih != 0) {
          errorMessages.push(`Chart Of Account is required.`);
        } else if (
          (selisih != 0 && el.amount == "") ||
          (selisih != 0 && el.amount == "0")
        ) {
          errorMessages.push(`Amount can't be 0 / blank.`);
        }
        valueCoas += parseFloat(el.amount.toString().replace(/,/g, ""));
        el.amount = parseFloat(el.amount.toString().replace(/,/g, ""))
          .toString()
          .replace(/,/g, "");
      });
      let adjustedSelisih = selisih > 0 ? -selisih : Math.abs(selisih);
      console.log("Value Coa", valueCoas);
      if (!isNaN(valueCoas) && valueCoas != adjustedSelisih) {
        errorMessages.push(
          `Amount must be ${adjustedSelisih
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.`
        );
      }
      console.log(
        "data total invoice",
        parseFloat(this.table.total_inv_value.replace(/,/g, ""))
      );
      console.log("data selisih", selisih);
      console.log("data coas", valueCoas);
      console.log("post data");
      console.log("param data", this.paramData());
      if (errorMessages.length > 0) {
        errorMessages.forEach((errorMessage) => {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: errorMessage,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
      } else {
        this.$vs.loading();
        this.$http
          .post("/api/v1/pi/create-invoice-manual-ocr", this.paramData())
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$vs.loading.close();
          });
      }
    },
    putData() {
      let itemValGr = parseFloat(
        this.table.item_value_gr.toString().replace(/,/g, "")
      );
      let taxVal = parseFloat(
        this.table.tax_value.toString().replace(/,/g, "")
      );
      let totVal = parseFloat(
        this.table.total_inv_value.toString().replace(/,/g, "")
      );
      var selisih = itemValGr + taxVal - totVal;
      var valueCoas = 0;
      let errorMessages = [];
      this.invoiceCoas.forEach((el, index) => {
        console.log("COA", el);
        if (el.coa != null && el.coa.Code.startsWith("7") && el.cc == null) {
          errorMessages.push(`Cost Center is required.`);
        } else if (el.coa == null && selisih != 0) {
          errorMessages.push(`Chart Of Account is required.`);
        } else if (
          (selisih != 0 && el.amount == "") ||
          (selisih != 0 && el.amount == "0")
        ) {
          errorMessages.push(`Amount can't be 0 / blank.`);
        }
        valueCoas += parseFloat(el.amount.toString().replace(/,/g, ""));
        el.amount = parseFloat(el.amount.toString().replace(/,/g, ""))
          .toString()
          .replace(/,/g, "");
      });
      let adjustedSelisih = selisih > 0 ? -selisih : Math.abs(selisih);
      if (!isNaN(valueCoas) && valueCoas != adjustedSelisih) {
        errorMessages.push(
          `Amount must be ${adjustedSelisih
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.`
        );
      }
      console.log(
        "data total invoice",
        parseFloat(this.table.total_inv_value.replace(/,/g, ""))
      );
      console.log("data selisih", selisih);
      console.log("data coas", valueCoas);
      console.log("post data");
      console.log("param data", this.paramPutData());
      if (errorMessages.length > 0) {
        errorMessages.forEach((errorMessage) => {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: errorMessage,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
      } else {
        this.$vs.loading();
        this.$http
          .post("/api/v1/pi/update-invoice-ocr", this.paramPutData())
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$vs.loading.close();
          });
      }
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("attachment_type", this.attachment_type);
      form.append("file", this.file);
      form.append("type", this.file.type);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.file.push({
              id: resp.data.attachment.ID,
              name: resp.data.attachment.NameFile,
              path: resp.data.attachment.PathFile,
              doc_type: resp.data.attachment.DocType,
            });
            this.attachment_ids.push(parseInt(resp.data.attachment.ID));
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // let onetime = {}
            // onetime.ID = 0
            // onetime.PaymentTermID = 0
            // onetime.Code = "Onetime"
            // onetime.Name = "Onetime"
            // this.optionSupplier = [onetime].concat(resp.data.records);
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWhTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            length: 0,
            tax_group: "WHT",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWhTax = resp.data.records;
            if (this.piId) {
              // this.getData()
              this.table.inv_code = this.invCode != 0 ? this.invCode : null;
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get WH Tax",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouse-territory", {
          params: {
            order: "warehouse_name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWarehouse = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrder() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po", {
          params: {
            supplier_id: this.supplier.ID,
            warehouse_id: this.warehouse.warehouse_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPO = resp.data.records;
            console.log("data po", resp.data.records);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrderNt() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po-nt", {
          params: {
            supplier_id: this.supplier.ID,
            warehouse_id: this.warehouse.warehouse_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPO = resp.data.records_code;
            this.optionPO2 = resp.data.records;
            console.log("data po nt", resp.data.records_code);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseReceiveNt() {
      let optionGr = [];
      let poDates = [];
      let uniquePOs = new Set();
      this.po.map((d) => {
        let statusError = false;
        console.log("PO NT", this.optionPO2);
        this.optionPO2.map((e) => {
          if (statusError) {
            return;
          }
          if (e.code == d.code && e.project) {
            console.log("tes 1");
            this.$vs.notify({
              title: "Error",
              text: "Multiple PO only for Non project",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.po = [];
            this.gr = [];
            this.po_date = null;
            this.gr_dates = null;
            optionGr = [];
            statusError = true;
            return;
          } else if (e.code == d.code && e.project == false) {
            console.log("tes 2");
            if (!uniquePOs.has(e.po_date)) {
              poDates.push(e.po_date);
              uniquePOs.add(e.po_date);
            }
            optionGr.push(e);
          }
        });
      });

      this.po_date = poDates.length > 0 ? poDates.join() : [];
      this.optionGR = optionGr;
      // this.po_date = optionGr.length > 0 ? optionGr[0].po_date : null
    },
    getListItemNt() {
      let bast_id = [];
      let gr_code = [];
      let gr_date = [];
      let payment_term_name = [];
      let po_id = [];
      let po_code = [];
      // let gr_dn_date = [];
      // let gr_do_number = [];
      console.log("CODE PO NT", this.gr);
      let uniquePoIds = new Set();
      let uniquePoCodes = new Set();
      this.gr.map(function (obj) {
        bast_id.push(obj.id);
        gr_code.push(obj.bast_code);
        gr_date.push(obj.date);
        payment_term_name.push(obj.payment_term_name);
        if (
          !uniquePoIds.has(obj.po_number_id) &&
          !uniquePoCodes.has(obj.code)
        ) {
          uniquePoIds.add(obj.po_number_id);
          uniquePoCodes.add(obj.code);
          po_id.push(obj.po_number_id);
          po_code.push(obj.code);
        }
        // gr_dn_date.push(obj.dn_date);
        // gr_do_number.push(obj.delivery_order_number);
      });

      // this.received_date = gr_date
      //   .map(function (e) {
      //     return e;
      //   })
      //   .sort()
      //   .reverse()[0];

      this.bast_ids = bast_id.join(";");
      this.gr_codes = gr_code.join(";");
      this.gr_dates = gr_date.join(";");
      this.payment_term_name_po = payment_term_name.join();
      this.po_id = po_id.join(";");
      this.po_code = po_code.join(";");

      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/list-bast-item", {
          bast_ids: bast_id.join(),
          // purchase_order_id: this.po.id.toString(),
        })
        .then((resp) => {
          if (resp.code == 200) {
            // this.table.item_value = (0).toFixed(2);
            this.table.charges_value = (0).toFixed(2);
            this.table.tax_value = (0).toFixed(2);
            this.table.discount_value = (0).toFixed(2);
            // this.table.total_inv_value = (0).toFixed(2);

            let dataCheck = [];
            this.statusItemDouble = false;
            console.log("data bast line", resp.data.records);
            resp.data.records.map((obj) => {
              if (typeof dataCheck[obj.item_unit_id] === "undefined") {
                // jika data tidak ada
                dataCheck[obj.item_unit_id] =
                  obj.item_name + obj.item_unit + obj.price_po.toString();
              } else {
                // jika data item unit sudah ada
                if (
                  dataCheck[obj.item_unit_id] !=
                  obj.item_name + obj.item_unit + obj.price_po.toString()
                ) {
                  // cek apakah price nya tidak sama
                  // mix2-10519 disable validasi item double
                  // this.statusItemDouble = true
                  // this.$vs.notify({
                  //   title: "Error",
                  //   text: obj.item_name + " have difference price.",
                  //   color: "danger",
                  //   position: "top-right",
                  //   iconPack: "feather",
                  //   icon: "icon-check",
                  // });
                }
              }
              this.table.data.push({
                item_name: obj.item_name,
                project: obj.project,
                item_unit: obj.item_unit,
                qty_received: obj.qty_received.toString(),
                tax_rate: parseFloat(obj.tax_rate),
                wh_tax_rate: 0,
                wh_tax_id: 0,
                wh_tax_object: null,
                amount_uom: parseInt(obj.amount_uom),
                subtotal: "0.00",
                quantity: obj.qty_received.toString(),
                tax: "0.00",
                wh_tax: "0.00",
                net:
                  parseFloat(obj.progress_bast) == 0
                    ? (obj.qty_received * parseFloat(obj.price_po))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : (
                        (parseFloat(obj.progress_bast) / 100) *
                        obj.qty_po *
                        parseFloat(obj.price_po)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                total:
                  parseFloat(obj.progress_bast) == 0
                    ? (
                        obj.qty_received * parseFloat(obj.price_po) +
                        parseFloat(obj.tax)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : (
                        (parseFloat(obj.progress_bast) / 100) *
                          obj.qty_po *
                          parseFloat(obj.price_po) +
                        parseFloat(obj.tax)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                pr_id: obj.pr_id,
                discount: parseFloat(obj.discount_po)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_unit_id: obj.item_unit_id,
                price: Math.round(parseFloat(obj.price_po))
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                price_po: obj.price_po.toString(),
                qty_po: obj.qty_po.toString(),
                progress_po: parseFloat(obj.progress_po),
                progress_bast: parseFloat(obj.progress_bast),
              });
              let netValue =
                parseFloat(obj.progress_bast) == 0
                  ? obj.qty_received * parseFloat(obj.price_po)
                  : (parseFloat(obj.progress_bast) / 100) *
                    obj.qty_po *
                    parseFloat(obj.price_po);
              netValue = Math.floor(netValue);
              console.log("net value", netValue);
              // fungsi untuk maping value po,gr dengan value ocr
              // this.table.data_ocr = this.table.data_ocr.filter((ocr) => {
              //   let ocrValue = parseInt(ocr.net_ocr.replace(/,/g, ''));
              //   console.log("OCR VALUE",ocrValue)
              //   ocr.isMatched = (netValue == ocrValue);
              //   return ocr;
              // });
            });
            this.cal();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get list item",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseReceive() {
      this.$vs.loading();
      console.log("purchase_order_id", this.po_ids);
      console.log("payment_term", this.po[0]);
      this.$http
        .get("/api/v1/pi/list-gr", {
          params: {
            purchase_order_ids: this.po_ids,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionGR = resp.data.records;
            console.log("list gr", resp.data.records);
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get GR option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getListItem() {
      let gr_id = [];
      let gr_code = [];
      let gr_date = [];
      let gr_dn_date = [];
      let gr_do_number = [];
      // let po_id = [];
      // let po_code = [];
      this.gr.map(function (obj) {
        console.log(obj);
        gr_id.push(obj.id);
        gr_code.push(obj.code);
        gr_date.push(obj.date);
        gr_dn_date.push(obj.dn_date);
        gr_do_number.push(obj.delivery_order_number);
        // po_id.push(obj.purchase_order_id);
        // po_code.push(obj.code);
      });

      console.log(this.gr);

      this.dn_date = gr_dn_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];
      // this.received_date = gr_date
      //   .map(function (e) {
      //     return e;
      //   })
      //   .sort()
      //   .reverse()[0];

      this.grOrderNumber = gr_do_number.join();
      this.gr_ids = gr_id.join(";");
      this.gr_codes = gr_code.join(";");
      this.gr_dates = gr_date.join(";");
      // this.po_id = po_id.join()
      // this.po_code = po_code.join()

      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/list-gr-item", {
          purchase_receive_ids: gr_id.join(),
          purchase_order_id: this.po[0].id.toString(),
          // purchase_order_id: this.po.id.toString(),
        })
        .then((resp) => {
          console.log("list data gr", resp);
          if (resp.code == 200) {
            let _this = this;
            // this.table.item_value = (0).toFixed(2);
            this.table.charges_value = (0).toFixed(2);
            this.table.tax_value = (0).toFixed(2);
            this.table.discount_value = (0).toFixed(2);
            // this.table.total_inv_value = (0).toFixed(2);

            resp.data.records.map((obj) => {
              let subtotal1 =
                parseFloat(obj.qty_received) * parseFloat(obj.price_po);
              let netPo = subtotal1 - parseFloat(obj.discount_po);
              var taxRate = 0;
              if (obj.tax != 0) {
                taxRate = parseFloat(obj.tax_rate);
              }
              let val_tax = netPo * (taxRate / 100);
              let total1 = parseFloat(obj.net) + parseFloat(val_tax);
              console.log("VALUE TAX RATE", obj.tax_rate);
              console.log("VALUE TAX ORI", obj.tax);
              console.log("VALUE TAX CALCULATE", val_tax);
              console.log("VALUE NET PO", obj.net);
              console.log("VALUE NET CALCULATE PO", parseFloat(netPo));
              this.table.data.push({
                item_name: obj.item_name,
                item_unit: obj.item_unit,
                qty_received: obj.qty_received,
                tax_rate: parseFloat(taxRate),
                amount_uom: parseInt(obj.amount_uom),
                // subtotal: "0.00",
                quantity: obj.qty_received,
                // discount: "0.00",
                tax_received: Math.floor(val_tax),
                net_received: obj.net,
                discount_received: obj.discount_po,

                pr_id: obj.pr_id.toString(),
                discount_po: obj.discount_po,
                item_unit_id: obj.item_unit_id,
                price_po: obj.price_po.toString(),
                qty_po: obj.qty_po,
                subtotal: parseFloat(subtotal1)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                total: parseFloat(total1)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                price: Math.round(parseFloat(obj.price_po))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount: parseFloat(obj.discount_po)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: Math.floor(parseFloat(val_tax))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                net: parseFloat(obj.net)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // id: element.id,
              });
              // fungsi untuk maping value po,gr dengan value ocr
              // this.table.data_ocr = this.table.data_ocr.filter((ocr) => {
              //   let ocrValue = parseInt(ocr.net_ocr.replace(/,/g, ''));
              //   console.log("OCR VALUE",ocrValue)
              //   ocr.isMatched = (parseInt(obj.net) == ocrValue);
              //   return ocr;
              // });
              this.cal();
            });

            resp.data.data_charge.map((obj) => {
              this.table.data_charges.push({
                value_po: obj.value,
                tax_po: obj.tax,
                value: "0.00",
                tax: "0.00",
                // id: element.id,
                charge_name: obj.charge_name,
                tax_rate: parseInt(obj.tax_rate),
              });
              _this.cal();
            });
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get list item",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getInvoiceBaseline() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/invoice-baseline/" + this.supplier.ID)
        .then((resp) => {
          if (resp.code == 200) {
            this.invoice_baseline = resp.data.invoice_baseline;
          } else {
            this.$vs.notify({
              title: "Warning",
              text: "Invoice baseline supplier not set",
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getAttachmentType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/attachment-type", {
          params: {
            group: "Supplier Invoice",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionAttachmentType = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get attachment type",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line-tempocr/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.territory_id = resp.data.territory_id;
            let _this = this;
            this.selectedRole = {
              display_name: resp.data.display_name_role,
              name: resp.data.name_role,
              id: resp.data.role_id,
            };
            this.table.vat_expired = resp.data.vat_expired;
            if (resp.data.is_skb != 1) {
              this.table.is_skb = 0;
            } else {
              this.table.is_skb = resp.data.is_skb;
            }

            this.top = resp.data.top;
            this.invoice_number = resp.data.inv_number;
            this.supplier_code =
              resp.data.supplier_code + " - " + resp.data.supplier_name;
            this.supplier_contact_name = resp.data.supplier_contact_name;
            this.supplier_phone = resp.data.supplier_phone;
            this.supplier = {
              ID: resp.data.supplier_id,
              Code: resp.data.supplier_code,
              Name: resp.data.supplier_name,
            };
            this.po_code = resp.data.po_code;
            this.warehouse_name = resp.data.warehouse_name;
            this.gr_codes = resp.data.gr_codes;
            this.po_date = resp.data.po_date;
            this.gr_dates = resp.data.gr_dates;
            this.tr_number = resp.data.tr_number;
            this.tr_date =
              moment(String(resp.data.tr_date)).format("YYYY-MM-DD") ==
              "0001-01-01"
                ? ""
                : new Date(resp.data.tr_date);

            let wh_tax_val = 0;
            let item_value_gr = 0;
            let tax_value_gr = 0;
            let tax_received = 0;
            let discount_value_gr = 0;
            let totAmount = 0;
            if (resp.data.data_coa.length > 0) {
              _this.invoiceCoas = [];
              resp.data.data_coa.forEach(function (element, index) {
                totAmount += parseFloat(
                  element.amount.toString().replace(/,/g, "")
                );
                _this.invoiceCoas.push({
                  coa: {
                    ID: element.coa,
                    Code: element.coa_code,
                    Name: element.coa_name,
                  },
                  cc: {
                    ID: element.cc,
                    Code: element.cc_code,
                    Name: element.cc_name,
                  },
                  amount: parseFloat(element.amount)
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  description: element.description,
                  coa_id: element.coa,
                  coa_code: element.coa_code,
                  coa_name: element.coa_name,
                  cc_id: element.cc,
                  cc_code: element.cc_code,
                  cc_name: element.cc_name,
                });
              });
            }
            if (resp.data.data_line.length > 0) {
              resp.data.data_line.forEach(function (element, index) {
                tax_received = element.tax;
                let optionWHT = null;
                if (resp.data.po_type == "1") {
                  item_value_gr += element.net_inv;
                  tax_value_gr += tax_received;
                  discount_value_gr += element.discount;
                  // const searchIndex = _this.optionWhTax.findIndex((wht) => wht.ID == element.wh_tax_id);
                  // optionWHT = _this.optionWhTax[searchIndex]
                  if (Array.isArray(_this.optionWhTax)) {
                    const searchIndex = _this.optionWhTax.findIndex(
                      (wht) => wht.ID == element.wh_tax_id
                    );
                    if (searchIndex !== -1) {
                      optionWHT = _this.optionWhTax[searchIndex];
                    }
                  }
                } else {
                  item_value_gr += element.net_inv;
                  tax_value_gr += tax_received;
                  discount_value_gr += element.discount;
                }
                wh_tax_val += element.wh_tax;
                _this.table.data.push({
                  price_po: element.price_po.toString(),
                  // discount_po: element.discount,
                  // net_po: element.net_po,
                  // tax_po: element.tax,
                  discount_received: element.discount,
                  net_received: element.net_received,
                  tax_received: tax_received,
                  progress_po: element.progress_po,
                  progress_bast: element.progress_bast,
                  tax_rate: element.tax_rate,
                  wh_tax_object: optionWHT,
                  wh_tax_id: element.wh_tax_id,
                  wh_tax: element.wh_tax
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  net: element.net_inv
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  tax: element.tax
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  quantity: element.quantity
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  item_name: element.item_name,
                  item_unit: element.item_unit,
                  id: element.id,
                  amount_uom: element.amount_uom,
                  qty_received: element.qty_received.toString(),
                  discount: element.discount_inv
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  subtotal: parseFloat(element.subtotal)
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  price: parseFloat(element.price)
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  total: (parseFloat(element.net_inv) + parseFloat(element.tax))
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                });
                // _this.cal()
              });
              this.table.wh_tax_value = wh_tax_val
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              resp.data.data_charge.forEach(function (element, index) {
                _this.table.data_charges.push({
                  value: element.value
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  tax: element.tax
                    .toFixed(2)
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  id: element.id,
                  charge_name: element.charge_name,
                  tax_rate: element.tax_rate,
                });
                // _this.cal()
              });
            }
            // this.warehouse_id = resp.data.warehouse_id;
            // this.supplier_id = resp.data.supplier_id;
            // this.supplier_code = resp.data.supplier_code;
            // this.supplier_contact_name = resp.data.supplier_contact_name;
            // this.supplier_phone = resp.data.supplier_phone;
            // this.invoice_baseline = resp.data.invoice_baseline;
            // this.gr_codes = resp.data.gr_codes;
            // this.dn_date = resp.data.dn_date;
            // this.po_date = resp.data.po_date;
            // this.po_date = moment(String(resp.data.po_date)).format("YYYY-MM-DD");
            // this.gr_dates = resp.data.gr_dates;

            this.received_date = resp.data.received_date;
            this.external_number = resp.data.external_number;
            this.barcode = resp.data.barcode;
            this.supplier_name = resp.data.supplier_name;
            this.po_type = resp.data.po_type;
            this.invoice_type = "Invoice";
            this.create_date = resp.data.created_date;
            this.released_date = resp.data.released_date;
            // console.log("RELEASED DATE",resp.data.released_date)
            if (resp.data.po_type == "0") {
              this.trade_invoice = resp.data.po_type;
            }
            this.table.inv_date =
              this.invCode != 0 ? new Date(resp.data.inv_date) : null;
            if (resp.data.payment_term_id !== 0) {
              this.table.payment_term = {
                id: resp.data.payment_term_id,
                name: resp.data.payment_term_name,
                due_invoice_period: 0,
              };
              this.$nextTick(() => {
                this.due_date = this.dueDate;
                this.due_date_ori = this.dueDate;
              });
            }
            this.getOptionAccountBank(resp.data.supplier_id, resp.data.bank_id);
            // console.log("TES BANK",resp.data.territory_id + "-" + resp.data.bank_id + "-" + resp.data.account_number)
            // if (resp.data.bank_id != 0 || resp.data.bank_id != "") {
            //   this.table.bank = {
            //     bank_name : resp.data.bank_name,
            //     bank_id: resp.data.bank_id,
            //     account_name: resp.data.account_name,
            //     account_number: resp.data.account_number,
            //     // bank_branch: "Branch " + resp.data.territory_name,
            //     territory_code: resp.data.territory_code,
            //     territory_name: resp.data.territory_name,
            //     territory_id: resp.data.territory_id,
            //     track_by: resp.data.territory_id + "-" + resp.data.bank_id + "-" + resp.data.account_number
            //   }
            // }
            this.notes = resp.data.supplier_invoice_notes.toString().split(";");
            // this.table.account_name = resp.data.account_name;
            this.currency_code = resp.data.currency_code;
            // this.table.account_number = resp.data.account_number;
            var selisih =
              item_value_gr +
              resp.data.tax_value -
              resp.data.total_invoice_value +
              totAmount;
            this.table.difference_value = selisih
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.discount_value_gr = discount_value_gr
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_value_faktur = resp.data.total_value_faktur
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value_old = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value_gr = (
              item_value_gr + Math.floor(tax_value_gr)
            )
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value_faktur = resp.data.item_value_faktur
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.item_value_gr = resp.data.item_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value_gr = item_value_gr
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value_gr = resp.data.charge_value_received
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_faktur = resp.data.tax_faktur
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value_old = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value_gr = Math.floor(tax_value_gr)
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value_ori = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.tax_from_value = resp.data.tax_from_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.account_name = resp.data.account_name;
            // this.table.account_number = resp.data.account_number;
            // this.table.po_code = resp.data.po_code;
            // this.table.vendor = resp.data.vendor;
            // this.table.data = resp.data.data_line;
            // this.table.data_charges = resp.data.data_charge;

            // resp.data.data_line_ocr.forEach(function (element, index) {
            //   let subtotal_rill = element.price_ocr * element.quantity_ocr;
            //   _this.table.data_ocr.push({
            //     net_ocr: element.net_ocr
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax_ocr: element.tax_ocr
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     // wh_tax_ocr: element.wh_tax_ocr
            //     //   .toFixed(2)
            //     //   .toString()
            //     //   .replace(/,/g, "")
            //     //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     // wh_tax_value_ocr: element.wh_tax_value_ocr
            //     //   .toFixed(2)
            //     //   .toString()
            //     //   .replace(/,/g, "")
            //     //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     quantity_ocr: element.quantity_ocr
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     discount_ocr: element.discount_ocr
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     price_ocr: element.price_ocr
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     subtotal_ocr: subtotal_rill
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     item_name_ocr: element.item_name_ocr,
            //     item_unit_ocr: element.item_unit_ocr,
            //     id_temp: element.id,
            //   });
            //   // _this.cal()
            // });

            resp.data.data_attachment.map((r, i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              });
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleWhTax(i) {
      if (this.table.data[i].wh_tax_object != null) {
        this.table.data[i].wh_tax = Math.floor(
          (this.table.data[i].wh_tax_object.Rate / 100) *
            parseFloat(this.table.data[i].net.replace(/,/g, ""))
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.data[i].wh_tax_rate = this.table.data[i].wh_tax_object.Rate;
        this.table.data[i].wh_tax_id = this.table.data[i].wh_tax_object.ID;
        this.$nextTick(() => {
          this.oldValue[i] = this.newValue[i];
        });
        this.newValue[i] = this.table.data[i].wh_tax_rate;
        if (this.oldValue[i] != this.newValue[i]) {
          console.log("tesssssssssss");
          // this.cal();
        }
      }
      let f = 0;
      this.table.data.map((r, i) => {
        if (typeof this.table.data[i].wh_tax === "string") {
          f += Math.floor(
            parseFloat(this.table.data[i].wh_tax.replace(/,/g, ""))
          );
        } else {
          f += Math.floor(this.table.data[i].wh_tax);
        }
      });
      console.log("WH TAX", f);
      this.table.wh_tax_value = f
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async previewAttachment(path) {
      const url = await this.previewFileAwsS3(path);
      if (url) {
        this.attachmentPath = url;
        this.showAttachment = true;
      }
    },
    handleChangePaymentTerm() {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        if (result[0] != undefined) {
          this.table.payment_term.due_invoice_period =
            result[0].due_invoice_period;
        }

        if (this.supplier.Code.toLowerCase() == "onetime") {
          if (this.table.payment_term != null && this.table.inv_date != null) {
            this.due_date = moment(String(this.table.inv_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY");
            this.due_date_ori = this.due_date;
          }
        } else {
          if (this.invoice_baseline == "Invoice Date") {
            this.due_date = moment(String(this.table.inv_date))
              .add(result[0].due_invoice_period, "days")
              .format("MM/DD/YYYY");
            this.due_date_ori = this.due_date;
          } else if (this.invoice_baseline == "Invoice Create Date") {
            // console.log(moment())
            // console.log(result[0].due_invoice_period)
            this.due_date = moment()
              .add(result[0].due_invoice_period, "days")
              .format("MM/DD/YYYY");
            this.due_date_ori = this.due_date;
            // console.log(this.due_date)
          } else if (this.invoice_baseline == "Delivery Note Date") {
            this.due_date = moment(String(this.dn_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY");
            this.due_date_ori = this.due_date;
          } else if (this.invoice_baseline == "GR Date") {
            this.due_date = moment(String(this.received_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY");
            this.due_date_ori = this.due_date;
          }
        }
      }
    },
  },
  mounted() {
    this.getData();
    this.getOptionWhTax();
    this.getOptionPaymentTerm();
    this.getOptionGlAccount();
    this.getOptionSupplier();
    this.getOptionWarehouse();
    this.getAttachmentType();
    this.getRefferRole();
  },
  computed: {},
  watch: {
    top: function () {
      if (
        this.top > 0 &&
        this.released_date &&
        this.released_date !== "0001-01-01T00:00:00Z"
      ) {
        const releasedDate = new Date(this.released_date);
        releasedDate.setDate(releasedDate.getDate() + parseInt(this.top));
        this.due_date = releasedDate.toISOString().split("T")[0]; // Format yyyy-MM-dd
      } else {
        this.due_date = this.due_date_ori;
      }
    },
    attachment: function () {
      this.attachment_type = this.attachment.DocType;
    },
    optionPaymentTerm: function () {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period =
          result[0].due_invoice_period;
      }
    },
    "table.payment_term": function () {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        if (result[0] != undefined) {
          this.table.payment_term.due_invoice_period =
            result[0].due_invoice_period;
        }

        // console.log("===== payment term", this.table.payment_term)
        // console.log("===== due invoice period",result[0].due_invoice_period)
        // console.log("===== invoice date",this.table.inv_date)
        // if (this.supplier.Code.toLowerCase() == 'onetime') {
        //   if (this.table.payment_term != null && this.table.inv_date != null) {
        //     this.due_date = moment(String(this.table.inv_date))
        //       .add(this.table.payment_term.due_invoice_period, "days")
        //       .format("MM/DD/YYYY")
        //     this.due_date_ori = this.due_date
        //   }
        // } else {
        //   if (this.invoice_baseline == "Invoice Date") {
        //     this.due_date = moment(String(this.table.inv_date))
        //       .add(result[0].due_invoice_period, "days")
        //       .format("MM/DD/YYYY")
        //     this.due_date_ori = this.due_date
        //   } else if (this.invoice_baseline == "Invoice Create Date") {
        //     // console.log(moment())
        //     // console.log(result[0].due_invoice_period)
        //     this.due_date = moment().add(result[0].due_invoice_period, "days").format("MM/DD/YYYY")
        //     this.due_date_ori = this.due_date
        //     // console.log(this.due_date)
        //   } else if (this.invoice_baseline == "Delivery Note Date") {
        //     this.due_date = moment(String(this.dn_date))
        //       .add(this.table.payment_term.due_invoice_period, "days")
        //       .format("MM/DD/YYYY")
        //     this.due_date_ori = this.due_date
        //   } else if (this.invoice_baseline == "GR Date") {
        //     this.due_date = moment(String(this.received_date))
        //       .add(this.table.payment_term.due_invoice_period, "days")
        //       .format("MM/DD/YYYY")
        //     this.due_date_ori = this.due_date
        //   }
        // }
      }
    },
    // "table.inv_date": function () {
    //   if (this.supplier.Code.toLowerCase() == 'onetime') {
    //     if (this.table.payment_term != null && this.table.inv_date != null) {
    //       this.due_date = moment(String(this.table.inv_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     }
    //   } else {
    //     if (this.table.payment_term != null && this.invoice_baseline == "Invoice Date") {
    //       this.due_date = moment(String(this.table.inv_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "Invoice Create Date") {
    //       this.due_date = moment()
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "Delivery Note Date") {
    //       this.due_date = moment(String(this.dn_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "GR Date") {
    //       this.due_date = moment(String(this.received_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     }
    //   }
    // },
    supplier: function () {
      if (this.piId == 0) {
        this.po = null;
        this.optionPO = [];
        this.warehouse = null;
        this.table.bank.bank_id = 0;
        this.supplier_name = this.supplier.Name;
        this.supplier_contact_name =
          this.supplier_contact_name == ""
            ? this.supplier.ContactName
            : this.supplier_contact_name;
        this.supplier_phone =
          this.supplier_phone == ""
            ? this.supplier.ContactMobile
            : this.supplier_phone;
        if (this.top == 0) {
          this.getDueInvoicePeriod(this.supplier.ID);
        }
        if (this.supplier.Code.toLowerCase() != "onetime") {
          this.getOptionAccountBank(this.supplier.ID, this.table.bank.bank_id);
          this.getInvoiceBaseline();
        }
        if (this.warehouse != null && this.po_type == "0") {
          this.getOptionPurchaseOrder();
        } else if (this.warehouse != null && this.po_type == "1") {
          this.getOptionPurchaseOrderNt();
        }
        this.optionPaymentTerm.map((v) => {
          if (v.id == this.supplier.PaymentTermID) {
            this.table.payment_term = {
              due_invoice_period: v.due_invoice_period,
              id: v.id,
              name: v.name,
              payment_method_id: v.payment_method_id,
              payment_method_name: v.payment_method_name,
            };
          }
        });
      }
    },
    warehouse: function () {
      if (this.supplier != null && this.po_type == "0") {
        this.getOptionPurchaseOrder();
      } else if (this.supplier != null && this.po_type == "1") {
        this.getOptionPurchaseOrderNt();
      }
      this.getOptionAccountBank(this.supplier.ID, this.table.bank.bank_id);
      this.territory_id = this.warehouse.territory_id;
      this.po = null;
      // this.table.bank = [];
    },
    po: function () {
      if (this.po_type == "1") {
        // non trade
        console.log("TES NT");
        this.getOptionPurchaseReceiveNt();
        this.gr = null;
        if (this.po.length > 0) {
          console.log("TES", this.po[0]);
          this.currency_code = this.po[0].currency_code;
          this.supplier_name = this.po[0].supplier_name;
          if (
            this.po[0].supplier_pic_name != "" &&
            this.po[0].supplier_pic_name != null
          ) {
            this.supplier_contact_name = this.po[0].supplier_pic_name;
          }

          if (
            this.po[0].supplier_pic_phone != "" &&
            this.po[0].supplier_pic_phone != null
          ) {
            this.supplier_phone = this.po[0].supplier_pic_phone;
          }

          if (
            this.po[0].supplier_address != "" &&
            this.po[0].supplier_address != null
          ) {
            this.supplier_address = this.po[0].supplier_address;
          }
          this.table.payment_term = {
            id: this.po[0].po_nt_payment_id,
            name: this.po[0].payment_term_name,
            due_invoice_period: 0,
          };
          console.log("table payment term", this.table.payment_term);
        }
      } else {
        // trade
        this.gr = null;
        this.currency_code = this.po.currency_code;
        this.po_code_external = this.po.external_code;
        if (this.po.length > 0) {
          this.po_date = this.po[0].date;
          this.currency_code = this.po[0].currency_code;

          let po_code = [];
          let po_id = [];
          for (let i = 0; i < this.po.length; i++) {
            po_code.push(this.po[i].code);
            po_id.push(this.po[i].id);
          }

          this.po_code = po_code.join(";");
          this.po_ids = po_id.join();
          this.po_id = po_id.join(";");
        }
        this.getOptionPurchaseReceive();

        this.table.payment_term = {
          id: this.po[0].payment_term_id,
          name: this.po[0].payment_term_name,
          due_invoice_period: 0,
        };
        console.log("PO code selected ", this.po_code);
        console.log("PO id selected ", this.po_id);
      }
    },
    gr: function () {
      this.table.data = [];
      this.table.data_charge = [];
      // this.po_date = this.gr[0].po_date;
      this.table.project = this.gr[0].project;
      if (this.po_type == "1") {
        this.getListItemNt();
      } else {
        this.getListItem();
      }
    },
    // "table.data": {
    //   handler: function() {
    //     for (let i = 0; i < this.table.data.length; i++) {
    //       if (this.table.data[i].wh_tax_object != null) {
    //         this.table.data[i].wh_tax = ((this.table.data[i].wh_tax_object.Rate / 100) * parseFloat(this.table.data[i].net.replace(/,/g, ""))).toFixed(2)
    //           .toString()
    //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //         this.table.data[i].wh_tax_rate = this.table.data[i].wh_tax_object.Rate
    //         this.table.data[i].wh_tax_id = this.table.data[i].wh_tax_object.ID
    //         this.$nextTick(() => {
    //           this.oldValue[i] = this.newValue[i]
    //         });
    //         this.newValue[i] = this.table.data[i].wh_tax_rate

    //         // console.log("old ", this.oldValue[i])
    //         // console.log("new ", this.newValue[i])

    //         if (this.oldValue[i] != this.newValue[i]) {
    //           this.cal()
    //         }
    //       }
    //     }
    //   },
    //   deep: true
    // }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.vs-con-table .vs-con-tbody {
  overflow-y: hidden !important;
}
</style>
