<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="10%"></vs-th>
          <vs-th>Scan Date</vs-th>
          <vs-th>Released Date</vs-th>
          <vs-th sort-key="code">Purchase Order</vs-th>
          <vs-th sort-key="supplier_name">Supplier</vs-th>
          <vs-th sort-key="warehouse_name">Warehouse</vs-th>
          <vs-th sort-key="due_date">Due Date</vs-th>
          <vs-th sort-key="delivery_number">GR Data</vs-th>
          <vs-th>Invoice</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Edit / Show Invoice">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(
                        data[indextr].id,
                        indextr,
                        data[indextr].po_code,
                        data[indextr].supplier_code,
                        data[indextr].supplier_name,
                        data[indextr].inv_code,
                        data[indextr].warehouse_code,
                        data[indextr].warehouse_name,
                        data[indextr].created_date,
                        data[indextr].due_date,
                        data[indextr].inv_date,
                        data[indextr].tr_number,
                        data[indextr].tr_date,
                      )
                    "
                    icon="icon-eye"
                  />
                </vx-tooltip>
                <vx-tooltip text="Reverse & Approval">
                  <vs-button
                    v-if="$store.getters['user/hasPermissions']('revers')"
                    color="danger"
                    type="line"
                    icon-pack="feather"
                    @click="reversal(tr)"
                    icon="icon-rewind"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>{{ dateFormat(tr.scan_date)}}</vs-td>
            <vs-td>{{ dateFormat(tr.released_date)}}</vs-td>
            <vs-td
              v-if="tr.po_code_external != ''"
              :data="data[indextr].po_code"
            >
              Internal Number : {{ data[indextr].po_code }}<br />
              External Number : {{ data[indextr].po_code_external }}<br />
              Date : {{ data[indextr].po_date }}<br />
              Delivery Number : {{ data[indextr].delivery_number }}
            </vs-td>
            <vs-td v-else :data="data[indextr].po_code">
              Number : {{ data[indextr].po_code }}<br />
              Date : {{ data[indextr].po_date }}<br />
              Delivery Number : {{ data[indextr].delivery_number }}
            </vs-td>
            <vs-td :data="data[indextr].supplier_name">
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_code }} -
                  {{ data[indextr].supplier_name }}</span
                >
              </div>
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_address }},
                  {{ data[indextr].supplier_city }} ({{
                    data[indextr].supplier_contact_name
                  }}
                  - {{ data[indextr].supplier_phone }})</span
                >
              </div>
            </vs-td>
            <vs-td :data="data[indextr].warehouse_name">
              <div class="vx-row ml-0">
                <span class="w-5/5">{{ data[indextr].warehouse_name }}</span>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].due_date">
              <div class="vx-row ml-0">
                <span class="w-5/5">{{
                  data[indextr].inv_code
                    ? dateFormat(data[indextr].due_date)
                    : "-"
                }}</span>
              </div>
            </vs-td>
            <vs-td> 
              Code : {{ data[indextr].gr_code }} <br>
              No Shipment : {{ data[indextr].delivery_number }}
            </vs-td>
            <vs-td>
              Total Value : 
              {{ 
                parseFloat(data[indextr].total_invoice_value)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
              }}<br>
              Tax : {{ parseFloat(data[indextr].tax)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  }} <br>
              External Number :
              {{ data[indextr].inv_code ? data[indextr].inv_code : "-" }} <br />
              Internal Number : 
              {{ data[indextr].code_internal }} <br>
              Date : 
              {{ dateFormat(data[indextr].inv_date) }} <br>
              Created At : {{ dateFormat(tr.created_date)}} <br>
              Created By : {{ tr.created_by }} <br>
              Barcode : {{ tr.barcode }} <br>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail
            @close="handleClose"
            :piId="piId"
            :vendor="vendor"
            :poCode="poCode"
            :invCode="invCode"
            :warehouseCode="warehouseCode"
            :warehouseName="warehouseName"
            :createdDate="createdDate"
            :dueDate="dueDate"
            :dueDate2="dueDate2"
            :invDate="invDate"
            :trNumber="trNumber"
            :trDate="trDate"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
    <div>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doReversal"
        @close="closeReversal"
        :active.sync="activePromptReversal"
      >
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <label class="vs-input--label">Reversal Date</label>
            <datepicker
              class="w-full"
              v-validate="'required'"
              :inline="false"
              name="Reversal Date"
              v-model="reversal_date"
              placeholder="Reversal date"
              format="MMMM dd, yyyy"
              label="Reversal Date"
              :disabledDates="disabledAfterToday"
            ></datepicker>
            <span
              class="text-danger text-sm"
              v-if="reversal_date == null && status_submit"
              >This field is required</span
            >
          </div>
        </div>
        <div class="con-exemple-prompt">
          Are you sure reverse & approval invoice {{ invCode }} ?
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Detail from "./detail.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Detail,
    Datepicker,
  },
  props: {
    warehouse_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
    supplier_id: {
      type: Number,
    },
    supplier_code: {
      type: String,
    },
    from_ocr_date: {
      type: String,
    },
    to_ocr_date: {
      type: String,
    },
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      piId: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      warehouseCode: null,
      warehouseName: null,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "date",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePrompt: false,
      activePromptReversal: false,
      createdDate: null,
      dueDate: null,
      invDate: null,
      trNumber: null,
      trDate: null,
      reversal_date: new Date(),
      disabledAfterToday: {
        from: new Date(), // Disable all dates after specific date
      },
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        let formattedDate = moment(String(date)).format("YYYY-MM-DD");
        if (formattedDate == "0001-01-01") {
          return ""
        } else {
          return formattedDate
        }
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: "3",
            warehouse_code: this.warehouse_code,
            from_inv_date: this.from_inv_date,
            to_inv_date: this.to_inv_date,
            from_due_date: this.from_due_date,
            to_due_date: this.to_due_date,
            from_ocr_date: this.from_ocr_date,
            to_ocr_date: this.to_ocr_date,
            supplier_id: this.supplier_id,
            supplier_code: this.supplier_code
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    reversal(data) {
      this.invCode = data.inv_code;
      this.piId = data.id;
      this.activePromptReversal = true;
    },
    closeReversal() {
      this.activePromptReversal = false;
      this.invCode = "";
    },
    doReversal() {
      this.$vs.loading();
      let param = {
        id: this.piId,
        status: "19",
        transaction_date: this.reversal_date,
      };
      this.$http
        .post("/api/v1/pi/change-status", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(
      id,
      index,
      poCode,
      vendorCode,
      vendorName,
      invCode,
      warehouseCode,
      warehouseName,
      createdDate,
      dueDate,
      invDate,
      trNumber,
      trDate
    ) {
      this.piId = id;
      this.vendor = vendorCode + " - " + vendorName;
      this.poCode = poCode;
      this.createdDate = moment(String(createdDate)).format("DD/MM/YYYY");
      this.dueDate = moment(String(dueDate)).format("DD/MM/YYYY");
      this.dueDate2 = dueDate;
      this.invDate = moment(String(invDate)).format("YYYY/MM/DD");
      this.invCode = invCode ? invCode : "0";
      this.table.data = [];
      this.table.data = this.data;
      this.warehouseCode = warehouseCode;
      this.warehouseName = warehouseName;
      this.trNumber = trNumber;
      this.trDate = trDate;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>